import React, { useState } from "react";
import { NavLink, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";

import IntlMessages from "util/IntlMessages";
export default () => {
  return (
    <ul className="nav-menu">
      <li>
        <NavLink to="/app/dashboard/index">
          <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
          <span className="nav-text">Dashboard</span>
        </NavLink>
      </li>

      <li>
        <NavLink to="/app/customers">
          <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
          <span className="nav-text">Customers </span>
        </NavLink>
      </li>
      <li className="ml-2">
        <NavLink to="/app/drivers">
          <i className="zmdi zmdi-car" />
          <span className="nav-text">Drivers</span>
        </NavLink>
      </li>
      {/* <li  >
      <NavLink to="/app/drivers">
        <i className="zmdi zmdi-local-taxi zmdi-hc-fw" />
        <span className="nav-text">Drivers </span>
      </NavLink>
    </li> */}

      {/* last hide  */}
      {/* <li className="menu collapse-box">
        <Button href="javascript:void(0)" className="cbutton">
          <i className="zmdi zmdi-local-taxi zmdi-hc-fw" />
          <span className="nav-text">Drivers</span>
        </Button>
        <ul className="sub-menu">
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/drivers/list">
              <i className="zmdi zmdi-local-taxi" />
              <span className="nav-text">All </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              className="prepend-icon cbutton"
              to="/app/drivers/approved"
            >
              <i className="zmdi zmdi-local-taxi" />
              <span className="nav-text">Approved </span>
            </NavLink>
          </li>
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/drivers/new">
              <i className="zmdi zmdi-local-taxi" />
              <span className="nav-text">New </span>
            </NavLink>
          </li>
        </ul>
      </li> */}

      <li className="menu collapse-box ml-2">
        <Button href="javascript:void(0)" className="cbutton">
          <i className="zmdi zmdi-file-text" />
          <span className="nav-text">Reports</span>
        </Button>
        <ul className="sub-menu">
          <li>
            <NavLink
              className="prepend-icon cbutton"
              to="/app/report/reportrestaurant-list"
            >
              <i className="zmdi zmdi-store zmdi-hc-fw" />
              <span className="nav-text">Admin Report</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              className="prepend-icon cbutton"
              to="/app/report/restaurant-list"
            >
              <i className="zmdi zmdi-store zmdi-hc-fw" />
              <span className="nav-text">Restaurants</span>
            </NavLink>
          </li>
        </ul>
      </li>
      <li className="menu collapse-box ml-2">
        <Button href="javascript:void(0)" className="cbutton">
          <i className="zmdi zmdi-car" />
          <span className="nav-text">Dispatcher</span>
        </Button>
        <ul className="sub-menu">
          <li>
            <NavLink
              className="prepend-icon cbutton"
              to="/app/dispatcher/listing"
            >
              <i className="zmdi zmdi-store zmdi-hc-fw" />
              <span className="nav-text">Listing</span>
            </NavLink>
          </li>
          {/* <li style={{marginLeft:".5em"}}>
            <NavLink
              className="prepend-icon cbutton"
              to="/app/dispatcher/mapView"
            >
              <i className="zmdi zmdi-pin" />
              <span className="nav-text">Map View</span>
            </NavLink>
          </li> */}
        </ul>
      </li>
      <li className="ml-2">
        <NavLink to="/app/birdEyeView/mapView">
          <i className="zmdi zmdi-pin" />
          <span className="nav-text">Bird Eye View</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/collection">
          <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
          <span className="nav-text">Business Type </span>
        </NavLink>
      </li>
      {/* <li>
        <NavLink to="/app/restaurentreport">
          <i className="zmdi zmdi-file-text" />
          <span className="nav-text">Restaurent Reports </span>
        </NavLink>
      </li> */}

      {/* last hide  */}
      {/* <li>
        <NavLink to="/app/car-types">
          <i className="zmdi zmdi-copy zmdi-hc-fw" />
          <span className="nav-text">Vehicle Types</span>
        </NavLink>
      </li> */}

      {/* <li  >
      <NavLink to="/app/trips">
        <i className="zmdi zmdi-receipt zmdi-hc-fw" />
        <span className="nav-text">Orders </span>
      </NavLink>
    </li> */}

      {/*<li>
        <NavLink to="/app/fresh-market">
          <i className="zmdi zmdi-shopping-basket zmdi-hc-fw" />
          <span className="nav-text"> Fresh Market </span>
        </NavLink>
  </li>*/}

      {/* last hide  */}
      {/* <li className="menu collapse-box">
        <Button href="javascript:void(0)" className="cbutton">
          <i className="zmdi zmdi-delicious zmdi-hc-fw" />
          <span className="nav-text">Fresh Market</span>
        </Button>
        <ul className="sub-menu">
          <li>
            <NavLink
              className="prepend-icon cbutton"
              to="/app/fresh-market"
              exact
            >
              <i className="zmdi zmdi-delicious" />
              <span className="nav-text">List</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              className="prepend-icon cbutton"
              to="/app/fresh-market/categories/list"
              exact
            >
              <i className="zmdi zmdi-delicious" />
              <span className="nav-text">Categories</span>
            </NavLink>
          </li>
        </ul>
      </li> */}

      {/* <li>
      <NavLink to="/app/units">
        <i className="zmdi zmdi-format-clear-all zmdi-hc-fw" />
        <span className="nav-text">Units</span>
      </NavLink>
    </li> */}

      <li className="menu collapse-box">
        <Button href="javascript:void(0)" className="cbutton">
          <i className="zmdi zmdi-store zmdi-hc-fw" />
          <span className="nav-text">Restaurants</span>
        </Button>
        <ul className="sub-menu">
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/categories">
              <i className="zmdi zmdi-store" />
              <span className="nav-text">Categories</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/vendor/list">
              <i className="zmdi zmdi-store" />
              <span className="nav-text">All </span>
            </NavLink>
          </li>
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/vendor/approved">
              <i className="zmdi zmdi-store" />
              <span className="nav-text">Approved </span>
            </NavLink>
          </li>
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/vendor/new">
              <i className="zmdi zmdi-store" />
              <span className="nav-text">New </span>
            </NavLink>
          </li>
        </ul>
      </li>

      <li className="menu collapse-box">
        <Button href="javascript:void(0)" className="cbutton">
          <i className="zmdi zmdi-store zmdi-hc-fw" />
          <span className="nav-text">Menu</span>
        </Button>
        <ul className="sub-menu">
          <li>
            <NavLink to="/app/menu">
              <i className="zmdi zmdi-view-list zmdi-hc-fw" />
              {/* <i className="zmdi zmdi-store" /> */}
              <span className="nav-text">Main Menu (Category)</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/items">
              <i className="zmdi zmdi-shopping-cart zmdi-hc-fw" />
              <span className="nav-text"> Menu Items</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/app/addonscategory">
              <i className="zmdi zmdi-star-circle zmdi-hc-fw" />
              <span className="nav-text">Add On Category</span>
            </NavLink>
          </li>
        </ul>
      </li>

      <li className="menu collapse-box">
        <Button href="javascript:void(0)" className="cbutton">
          <i className="zmdi zmdi-receipt zmdi-hc-fw" />
          <span className="nav-text">Orders</span>
        </Button>
        <ul className="sub-menu">
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/trips/list">
              <i className="zmdi zmdi-receipt" />
              <span className="nav-text">All</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/trips/completed">
              <i className="zmdi zmdi-receipt" />
              <span className="nav-text">Completed</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/trips/new">
              <i className="zmdi zmdi-receipt" />
              <span className="nav-text">Decline/Cancelled</span>
            </NavLink>
          </li>
        </ul>
      </li>

      <li>
        <NavLink to="/app/blog">
          <i className="zmdi zmdi-accounts-alt zmdi-hc-fw" />
          <span className="nav-text">Blogs</span>
        </NavLink>
      </li>

      <li>
        <NavLink to="/app/gif">
          <i className="zmdi zmdi-dot-circle zmdi-hc-fw" />
          <span className="nav-text">Promotion</span>
        </NavLink>
      </li>

      <li>
        <NavLink to="/app/compare">
          <i className="zmdi zmdi-arrows zmdi-hc-fw" />
          <span className="nav-text">Compare </span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/pricing">
          <i className="zmdi zmdi-money-box zmdi-hc-fw" />
          <span className="nav-text">Pricing </span>
        </NavLink>
      </li>

      <li>
        <NavLink to="/app/disputes">
          <i className="zmdi zmdi-label zmdi-hc-fw" />
          <span className="nav-text">Disputes</span>
        </NavLink>
      </li>
      {/* <li  >
      <NavLink to="/app/cuisines">
        <i className="zmdi zmdi-star zmdi-hc-fw" />
        <span className="nav-text">Cuisines</span>
      </NavLink>
    </li>*/}
      <li>
        <NavLink to="/app/promotions">
          <i className="zmdi zmdi-labels zmdi-hc-fw" />
          <span className="nav-text">Banner</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/promo-codes">
          <i className="zmdi zmdi-label zmdi-hc-fw" />
          <span className="nav-text">Promo Codes</span>
        </NavLink>
      </li>

      <li>
        <NavLink to="/app/contentPages">
          <i className="zmdi zmdi-file-text zmdi-hc-fw" />
          <span className="nav-text">Content Pages</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/faq">
          <i className="zmdi zmdi-comments zmdi-hc-fw" />
          <span className="nav-text">FAQ</span>
        </NavLink>
      </li>

      <li>
        <NavLink to="/app/contactquery">
          <i className="zmdi zmdi-comments zmdi-hc-fw" />
          <span className="nav-text">Support</span>
        </NavLink>
      </li>
      <li>
        <NavLink to="/app/notification">
          <i className="zmdi zmdi-notifications-active zmdi-hc-fw" />
          <span className="nav-text">Push Notification</span>
        </NavLink>
      </li>
      <li className="menu collapse-box">
        <Button href="javascript:void(0)" className="cbutton">
          <i className="zmdi zmdi-settings zmdi-hc-fw" />
          <span className="nav-text">Settings</span>
        </Button>
        <ul className="sub-menu">
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/users/list">
              <i className="zmdi zmdi-account" />
              <span className="nav-text">Admin Settings</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/settings/basic">
              <i class="zmdi zmdi-album"></i>

              <span className="nav-text">Basic Settings</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              className="prepend-icon cbutton"
              to="/app/settings/installation"
            >
              <i class="zmdi zmdi-remote-control"></i>

              <span className="nav-text">Installation Settings</span>
            </NavLink>
          </li>

          <li>
            <NavLink className="prepend-icon cbutton" to="/app/settings/mail">
              <i class="zmdi zmdi-email"></i>

              <span className="nav-text">Mail templates</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="prepend-icon cbutton" to="/app/settings/sms">
              <i class="zmdi zmdi-comment-text"></i>

              <span className="nav-text">SMS Templates</span>
            </NavLink>
          </li>
        </ul>
      </li>
    </ul>
  );
};
