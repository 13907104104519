import React, { Fragment } from "react";
import { Card, CardBody } from "reactstrap";

const NotFound = ({ fullSize }) => (
  <Fragment>
    <img
      alt="No data available"
      style={{
        width: "3%",
        ...(fullSize && {
          width: "100%",
          height: "100%",
          objectFit: 'contain'
        }),
      }}
      src={require("assets/images/loading.gif")}
    />
  </Fragment>
);

export default NotFound;
